<template>
  <Card>
    <div class="invoice-box">
      <Form ref="invoice" :model="invoice" :rules="invoiceRules" :label-width="100">
        <FormItem label="发票抬头: " prop="title">
          <Input
            size="large"
            type="text"
            class="input-box"
            v-model="invoice.title"
            placeholder="请输入发票抬头"
          />
        </FormItem>
        <FormItem label="税号: " prop="tfn">
          <Input
            size="large"
            type="text"
            class="input-box"
            v-model="invoice.tfn"
            placeholder="请输入税号"
          />
        </FormItem>
        <FormItem label="开票金额: " prop="invoice_account">
          <Input
            size="large"
            type="number"
            class="input-box"
            v-model="invoice.invoice_account"
            placeholder="请输入开票金额"
            disabled
          />
        </FormItem>
        <FormItem label="开户银行: " prop="bank_name">
          <Input
            size="large"
            type="text"
            class="input-box"
            v-model="invoice.bank_name"
            placeholder="请输入开户银行"
          />
        </FormItem>
        <FormItem label="银行账号: " prop="bank_account">
          <Input
            size="large"
            type="text"
            class="input-box"
            v-model="invoice.bank_account"
            placeholder="请输入银行账号"
          />
        </FormItem>
        <FormItem label="电子邮箱: " prop="email">
          <Input
            size="large"
            class="input-box"
            type="email"
            v-model="invoice.email"
            placeholder="请输入电子邮箱"
          />
        </FormItem>

        <div class="btn-area">
          <Button type="primary" @click="handleSubmit('invoice')"
            >确认提交</Button
          >
        </div>
      </Form>
    </div>
  </Card>
</template>

<script>
import Card from "@components/Card";

export default {
  name: "invoice",
  components: {
    Card
  },
  data() {
    return {
      orderId: "",
      invoice: {
        title: "",
        invoice_account: "",
        email: "",
        tfn: "",
        bank_account: "",
        bank_name: ""
      },
      invoiceRules: {
        title: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { type: "email", min: 6, message: "邮箱格式不正确", trigger: "blur" }
        ],
        tfn: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
        bank_account: [
          { required: true, message: "请输入银行账号", trigger: "blur" }
        ],
        bank_name: [
          { required: true, message: "请输入开户银行", trigger: "blur" }
        ]
      }
    };
  },
  created() {},
  mounted() {
    this.handleParam();
    this.getOrderDetail();
  },
  methods: {
    handleParam() {
      this.orderId = this.$route.params.orderId;
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.invoiceOrder(this.orderId);
          this.$Message.success("Success!");
        } else {
          this.$Message.error("Fail!");
        }
      });
    },
    async invoiceOrder(id) {
      let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      let param = {
        order_id: id,
        phone: userInfo.login_id
      };
      param = Object.assign(param, this.invoice);
      let res = await this.$fetch(
        "/api/api/buyer/order/invoice",
        {
          method: "post",
          data: param
        },
      );

      if (res) {
        if (res.code == "200") {
          this.$Message.success("开具成功");
          this.$router.push({ name: "orderList" });
        } else {
          this.$Message.error(res.data.message);
          if (res.data.code === "6000") {
            this.$router.push({ name: "login" });
          }
        }
      }
    },
    async getOrderDetail() {
      let userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
      let param = { creator_id: userInfo.id, order_id: this.orderId };
      let res = await this.$fetch(
        "/api/api/buyer/order/detail",
        {
          method: "post",
          data: param
        }
      );

      if (res) {
        if (res.code == "200") {
          this.invoice.invoice_account = res.resData.order_amount;

          if (res.resData.order_status != 5) {
            this.$Message.error("此订单不能开具发票");
            this.$router.push({ name: "order-list" });
          }
        }
      }
    }
  }
};
</script>

<style lang="stylus">
.invoice-box
    margin 60px auto 0
    width 640px

    .btn-area
      margin-top 60px
      button
        border-color #01C8FF
        background #01C8FF

    .input-box
      width 480px
</style>
